import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import moment, { Moment } from 'moment';
import { Notifiable } from 'classes/notifications/Notifiable.class';
import { Acteur } from 'classes/Acteur.class';

/**
 * Battle enregistrée dans Level Up
 */
export class Battle extends Notifiable
{
    public getUrl(): string {
        return "/level-up/battles/" + this.idBattle;
    }
    public getID(): number {
        return this.idBattle;
    }
    public notifType = 14;

    /**
     * Identifiant du battle
     */
    public idBattle: number;

    /**
     * Identifiant du référentiel métier
     */
    public idReferentielMetier: number;

    /**
     * Identifiant de l'émetteur
     * La personne qui lance le défi
     */
    public emetteur: Acteur;

    /**
     * Identifiant du récepteur
     * La personne qui est défiée
     */
    public recepteur: Acteur;

    /**
     * Date de création du défi (timestamp unix)
     */
    public dateCreation: Moment;

    /**
     * Date de début du défi pour le récepteur (timestamp unix)
     */
    public dateDebutRecepteur: Moment;

    /**
     * Nombre de points gagnés par l'émetteur
     * (lorsque le défi est fini)
     */
    public pointsEmetteur: number;

    /**
     * Nombre de points gagnés par le récepteur
     * (lorsque le défi est fini)
     */
    public pointsRecepteur: number;

    /**
     * Score de l'émetteur (à la fin de son tour)
     */
    public scoreEmetteur: number;

    /**
     * Score du récepteur (à la fin de son tour)
     */
    public scoreRecepteur: number;

    /**
     * Temps pris par l'émetteur pour faire son quiz
     * (nombre de secondes)
     */
    public tempsEmetteur: number;

    /**
     * Temps pris par le récepteur pour faire son quiz
     * (nombre de secondes)
     */
    public tempsRecepteur: number;

    /**
     * Lorsque le quiz est terminé on renseigne ici le gagnant
     */
    public gagnant: string;

    /**
     * Identifiant de la question en cours (émetteur)
     */
    public qEnCoursEmetteur: number;

    /**
     * Identifiant de la question en cours (récepteur)
     */
    public qEnCoursRecepteur: number;

    /**
     * Si l'utilisateur courant est le gagnant de la battle
     */
    public currentIsWinner: boolean;

    /**
     * Si l'utilisateur courant est l'emetteur de la battle
     */
    public currentIsEmetteur: boolean;

    /**
     * Si une revanche est possible
     */
    public canRevenge: boolean;

    /**
     * Si une battle est déjà en cours avec cet adversaire
     */
    public hasBattleEnCours: boolean;

    /**
     * Nombre de questions totales pour le quiz d'une battle
     */
    public nbQuestionsTotales: number;


    private levelUpService = LevelUpService.getInstance();


    /**
     * 
     *  Constructeur de la fiche
     * @param  mixed row
     * @return void
     */
    constructor(row?: DeepPartial<DeepRawify<Battle>>) {
        super();
        if (row) {
            this.idBattle = row['idBattle'] ?? null;
            this.idReferentielMetier = row['idReferentielMetier'] ?? null;
            if(row.emetteur) {
                this.emetteur = new Acteur(row.emetteur);
            }
            if(row.recepteur) {
                this.recepteur = new Acteur(row.recepteur);
            }
            this.dateCreation = row['dateCreation'] ? moment(row['dateCreation'], 'X') : moment();
            this.dateDebutRecepteur = row['dateDebutRecepteur'] ? moment(row['dateDebutRecepteur'], 'X') : null;
            this.pointsEmetteur = row['pointsEmetteur'] ?? null;
            this.pointsRecepteur = row['pointsRecepteur'] ?? null;
            this.scoreEmetteur = row['scoreEmetteur'] ?? null;
            this.scoreRecepteur = row['scoreRecepteur'] ?? null;
            this.tempsEmetteur = row['tempsEmetteur'] ?? null;
            this.tempsRecepteur = row['tempsRecepteur'] ?? null;
            this.gagnant = row['gagnant'] ?? null;
            this.qEnCoursEmetteur = row['qEnCoursEmetteur'] ?? null;
            this.qEnCoursRecepteur = row['qEnCoursRecepteur'] ?? null;
            this.currentIsWinner = row['currentIsWinner'] ?? false;
            this.currentIsEmetteur = row['currentIsEmetteur'] ?? false;
            this.canRevenge = row['canRevenge'] ?? false;
            this.hasBattleEnCours = row['hasBattleEnCours'] ?? false;
            this.nbQuestionsTotales = row['nbQuestionsTotales'] ?? 0;
        }
    }

    public save(): Promise<Battle> {
        return this.levelUpService.saveBattle(this);
    }

    /*public delete: () => Promise<boolean> = () => {
        return this.levelUpService.deleteFiche(this.idFiche);
    }*/

    public toRaw() {
        return {
            idBattle: this.idBattle,
            idReferentielMetier: this.idReferentielMetier,
            emetteur: this.emetteur?.idActeur,
            recepteur: this.recepteur?.idActeur,
            dateCreation: this.dateCreation && this.dateCreation.unix(),
            dateDebutRecepteur: this.dateDebutRecepteur && this.dateDebutRecepteur.unix(),
            pointsEmetteur: this.pointsEmetteur,
            pointsRecepteur: this.pointsRecepteur,
            scoreEmetteur: this.scoreEmetteur ,
            scoreRecepteur: this.scoreRecepteur,
            tempsEmetteur: this.tempsEmetteur,
            tempsRecepteur: this.tempsRecepteur,
            gagnant: this.gagnant,
            qEnCoursEmetteur: this.qEnCoursEmetteur,
            qEnCoursRecepteur: this.qEnCoursRecepteur,
        };
    }

}
