import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AddCircleSVG from '@material-ui/icons/AddCircle'
import ArrowBackSVG from '@material-ui/icons/ArrowBack'
import CancelSVG from '@material-ui/icons/Cancel'
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import InsertLinkSVG from '@material-ui/icons/InsertLink'
import InsertPhotoSVG from '@material-ui/icons/InsertPhoto'
import KeyboardArrowDownSVG from '@material-ui/icons/KeyboardArrowDown'
import VideoCamSVG from '@material-ui/icons/Videocam'
import { WithStyles, withStyles } from '@material-ui/styles'
import { Acteur } from 'classes/Acteur.class'
import { Post } from 'classes/murs/Post.class'
import { TypePost } from 'classes/murs/TypePost.class'
import Avatar from 'components/avatar/Avatar.component'
import ChoiceDialog from 'components/dialog/ChoiceDialog.hook.component'
import B64FilePickerComponent from 'components/filePicker/B64FilePicker.component'
import { B64File } from "classes/B64File.class";
import FilePickerComponent from 'components/filePicker/FilePicker.component'
import { Pacman } from 'components/pacman'
import { goBack } from 'connected-react-router'
import { navigationPush } from 'store/navigation/navigationActions';
import AddVideoLinkDialogComponent from 'features/admin/animations/src/editAnimation/quiz/editionQuiz/AddVideoLinkDialog.component'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import uniqueId from 'lodash/uniqueId'
import { Moment } from 'moment'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { connect, useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { RouteComponentProps } from 'react-router'
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions'
import { RootState } from 'store/types'
import { colors, filesIcons, Theme } from 'Theme'
import OverlayAvatarSVG from '../../../../../assets/visuels/overlay-avatar.svg'
import ChoseFromListDialogComponent from '../../../../components/dialog/ChoseFromListDialog.component'
import LienVideoSVG from '../../assets/icone_lien_video.svg'
import VideoGalerieSVG from '../../assets/icone_video_galerie.svg'
import { getPostAsync, loadTypesPostAsync, preUploadVideoAsync, resetPreUploadVideo, setPost, setShouldPostsListGetUpdated } from '../store/actions'
import style from './CreatePost.style'
import SchedulePostDialogComponent from './SchedulePostDialogComponent'
import TypePostIcon from './TypePostIcon.component'
import { setHeaderStore } from 'features/header/src/store/actions'
import { withRouter } from 'react-router-dom';
import useTeamUpStyle from 'TeamUp.style';
import clsx from 'clsx'
import InputAdornment from '@material-ui/core/InputAdornment'
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import Momindum from 'components/video/Momindum.component'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const stateToProps = ({ referentielMetier: { referentiel }, acteur: { current, droits }, murs: { canaux, typeMur, typesPost, isLoading, post, elements, preUploadVideoProgress, preUploadedVideoB64File } }: RootState) => ({
    typesPost,
    isLoading,
    post,
    moi: current,
    referentiel,
    elements,
    typeMur,
    canaux,
    preUploadVideoProgress,
    preUploadedVideoB64File,
    droits
})

const dispatchToProps = {
    loadTypesPostAsync: loadTypesPostAsync.request,
    goBack,
    snackbar: newSnackbar,
    navigationPush,
    setShouldPostsListGetUpdated,
    loadPost: getPostAsync.request,
    setPost,
    preUploadVideo: preUploadVideoAsync.request,
    resetPreUploadVideo,
    cancelVideoUpload: preUploadVideoAsync.cancel,
    setHeaderStore
}

interface CreatePostLocalProps {
    modify: boolean;
    idPost?: number;
    onClose?: (ok: boolean) => void
}

type CreatePostProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & CreatePostLocalProps
    & RouteComponentProps<{ id: string }>;

const RoundLinearProgress = withStyles({
    bar: {
        borderRadius: 30,
        backgroundColor: '#50BE87',
    },
})(LinearProgress);

const CreatePostComponent: React.FC<CreatePostProps> = (props: CreatePostProps) => {

    const dispatch = useDispatch();    

    const { match,
        typesPost,
        loadTypesPostAsync,
        isLoading,
        goBack,
        post,
        elements,
        modify,
        idPost,
        navigationPush,
        loadPost,
        typeMur,
        onClose,
        referentiel,
        setPost,
        classes,
        moi,
        canaux,
        setShouldPostsListGetUpdated,
        preUploadVideoProgress,
        preUploadedVideoB64File,
        preUploadVideo,
        resetPreUploadVideo,
        cancelVideoUpload,
        setHeaderStore,
        droits
    } = props;

    const teamUpStyle = useTeamUpStyle();

    const [statePost, setStatePost] = useState<Post>(new Post());
    const [openVideoLinkDialog, setOpenVideoLinkDialog] = useState(false);
    const [openSchedulePostDialog, setOpenSchedulePostDialog] = useState(false);
    const [openVideoWayDialog, setOpenVideoWayDialog] = useState(false);
    const [videoReady, setVideoReady] = useState(false);
    const [revealedThirdChoice, setRevealedThirdChoice] = useState(false);
    const [addVideoLinkMethod, setAddVideoLinkMethod] = useState<(link: string) => void>(handleCloseLinkDialog);
    const [videoDialog, setVideoDialog] = useState(false);
    const [videoPickerOpen, setVideoPickerOpen] = useState(false);
    const [openChosePostTypeFromListDialog, setOpenChosePostTypeFromListDialog] = useState(false);
    const [openChoseCanalFromListDialog, setOpenChoseCanalFromListDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
    const [anchorElSelectCanal, setAnchorElSelectCanal] = useState(null);
    const [anchorElSelectTypePost, setAnchorElSelectTypePost] = useState(null);
    const [stateCanaux, setStateCanaux] = useState<Array<string>>([]);
    const [publishingPost, setPublishingPost] = useState(false);
    const [loadingPost, setLoadingPost] = useState(false);
    const [envoiNotif, setEnvoiNotif] = useState(false);
    const [confirmerNotif, setConfirmerNotif] = useState(false); // ouverture boite confirmation envoi notif

    const [firstEffectDone, setFirstEffectDone] = useState(false);

    const [fileVideoBlob, setFileVideoBlob] = useState<string>(null); // used to store the "File" object in order to pre-visualize the video while uploading it
    const refSelectTypePost = useRef(null);
    const [idSelectTypePost] = useState(uniqueId('select-createpost-typepost-'));
    const refSelectCanal = useRef(null);
    const [idSelectCanal] = useState(uniqueId('select-createpost-canal-'));

    // const willMount = useRef(true);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    //pour régler le problème de ref component lié aux select
    useEffect(() => {
        setFirstEffectDone(true);
        if (!isLoading) {
            if (post && statePost.idMurPost != post.idMurPost) {
                setStatePost(cloneDeep(post));
                setRevealedThirdChoice(post.reponses.length === 3);
            }
            if (post && !isEqual(stateCanaux, [`${post.idReferentielMetier}`])) {
                setStateCanaux([`${post.idReferentielMetier}`]);
            }
        }
        if (!IS_ADMIN && !isDesktopOrLaptop) {
            setHeaderStore({
                pageTitle: modify ? 'Modifier un post' : 'Créer un post',
                leftComponents: [
                    <ArrowBackSVG onClick={() => props.goBack()} style={{ height: 24, width: 24, color: 'white', margin : 'auto', cursor: 'pointer'  }} />
                ],
                rightComponents: [],

                isPageFilter: false
            });
        }
    }, [])

    useEffect(() => {
        if (preUploadedVideoB64File != null) {
            setStatePost(prevStatePost => {
                const newStatePost = cloneDeep(prevStatePost);
                newStatePost.fileVideo = preUploadedVideoB64File;
                return newStatePost;
            });
            setFileVideoBlob(null);
        }
    }, [preUploadedVideoB64File])

    useEffect(() => {
        if (publishingPost && preUploadVideoProgress == 100 && !fileVideoBlob) {
            if (!envoiNotif || (envoiNotif && confirmerNotif)) {
                publishPost();
            } else if (envoiNotif) {
                setConfirmerNotif(true);
                setPublishingPost(false);
            }
        }
    }, [preUploadVideoProgress, publishingPost, fileVideoBlob])




    useEffect(() => {
        if (firstEffectDone) {
            if (anchorElSelectCanal === null && refSelectCanal != null) {
                setAnchorElSelectCanal(refSelectCanal.current)
            }
            if (anchorElSelectTypePost === null && refSelectTypePost != null) {
                setAnchorElSelectTypePost(refSelectTypePost.current)
            }
        }


    }, [anchorElSelectCanal, anchorElSelectTypePost, firstEffectDone])

    useEffect(() => {
        if (videoPickerOpen) {
            setVideoPickerOpen(false);
        }
    })

    if (modify) {
        useEffect(() => {
            if (!isLoading && typeMur != -1) {
                var id: number = idPost ? idPost : Number.parseInt(match.params.id);
                if (isNaN(id) || !id) {
                    navigationPush('/')
                }
                if (!post || post.idMurPost != id) {
                    setLoadingPost(true);
                    loadPost(id);
                } else if (post && post.idMurPost === id) {
                    setLoadingPost(false);
                }

                if (post && statePost.idMurPost != post.idMurPost) {
                    setEnvoiNotif(post.notification)
                    setStatePost(cloneDeep(post));
                    setStateCanaux([`${post.idReferentielMetier}`]);
                    setRevealedThirdChoice(post.reponses.length === 3);

                }
            }
        }, [post, idPost, match, statePost.idMurPost, typeMur])
    } else {
        useEffect(() => {
            if (!isLoading && typeMur != -1) {
                if (typesPost && !statePost.typePost && elements['type_post']) {
                    const id = Number.parseInt(match.params.id);
                    const tmpPost = cloneDeep(statePost);

                    if (isNaN(id) || !typesPost.hasOwnProperty(id)) {
                        const tmpTypePost = Object.values(typesPost)[0];
                        tmpPost.idType = tmpTypePost.idTypePost;
                        tmpPost.typePost = tmpTypePost;
                    } else {
                        tmpPost.idType = id;
                        tmpPost.typePost = typesPost[id];
                    }
                    setStatePost(tmpPost);
                }
            }
        }, [typesPost, elements, match, statePost.typePost, typeMur])

        // useEffect(() => {
        //     if (!isLoading && typeMur != -1) {
        //         if (typesPost && statePost && elements['type_post']) {
        //             const tmpPost = cloneDeep(statePost);
        //             const tmpTypePost = Object.values(typesPost)[0];
        //             tmpPost.idType = tmpTypePost.idTypePost;
        //             tmpPost.typePost = tmpTypePost;
        //             setStatePost(tmpPost);
        //         }
        //     }
        // }, [typesPost, typeMur])
    }

    useEffect(() => {
        if (!isLoading && typeMur != -1) {
            if (Object.keys(elements).length > 0 && !elements['create_post'] && !elements['create_post_public'] && !IS_ADMIN) {
                navigationPush('/')
            }
        }
    }, [elements, typeMur])

    useEffect(() => {
        if (!isLoading) {
            if (!modify && canaux && Object.values(canaux).length > 0 && referentiel) {
                if (Object.keys(canaux).includes('-1')) {
                    // setStateCanaux(['-1']);

                    // je force le canal à -1 si je n'ai que les droits public
                    // donc pas de canaux 
                    if (Object.values(canaux).length > 1) {
                        setStateCanaux([`${referentiel.idReferentielMetier}`]);
                    } else {
                        setStateCanaux(['-1']);
                    }

                } else {
                    setStateCanaux([`${referentiel.idReferentielMetier}`]);
                }
            }
        }
    }, [canaux, referentiel]);


    function handleVideoFromGallery(f: File) {
        preUploadVideo(f);
        setFileVideoBlob(URL.createObjectURL(f));
    }

    function handleCancelPreUploadVideo() {
        setPublishingPost(false);
        cancelVideoUpload();
        setStatePost(prevStatePost => {
            const newStatePost = cloneDeep(prevStatePost);
            newStatePost.fileVideo = null;
            return newStatePost;
        });
        setFileVideoBlob(null);
    }

    var handleCloseVideoLinkDialog = (linkVideo: string) => {
        if (ReactPlayer.canPlay(linkVideo) || Momindum.canPlay(linkVideo)) {
            statePost.lienVideo = linkVideo;
        } else if (linkVideo !== "") {
            props.snackbar({
                type: SnackbarType.WARNING,
                props: {
                    open: true,
                    autoHideDuration: 5000,
                    message: 'La vidéo n\'a pas pu être ajoutée car un problème est survenu.'
                }
            });
        }
        setOpenVideoLinkDialog(false);
    }

    //close link
    var handleCloseLinkDialog = (link: string) => {
        if (link) {
            let tmpPost: Post = cloneDeep(statePost);
            tmpPost.lienUrl = link;
            setStatePost(tmpPost);
        }
        setOpenVideoLinkDialog(false);
    }

    async function handlePublishClick() {
        setPublishingPost(true);
    }

    function handleCloseChosePostTypeFromListDialog(typePost: number) {
        let tmpPost = cloneDeep(statePost);
        tmpPost.idType = typePost;
        tmpPost.typePost = props.typesPost[typePost];
        setStatePost(tmpPost);
        setOpenChosePostTypeFromListDialog(false);
    }

    function handleCloseChoseCanalFromListDialog(canal: number) {
        setStateCanaux([`${canal}`])
        setOpenChoseCanalFromListDialog(false);
    }

    /**
     * Vérifie le format du fichier sélectionné par l'utilisateur puis l'associe avec un post
     * @param file fichier sélectionné par l'utilisateur
     * @param estImage true si on est dans le cas d'une image
     */
     function handleChangeFile(file: B64File, estImage: boolean) {
        let extEnabled = ['png', 'jpeg', 'jpg', 'gif', 'pdf', 'xls', 'xlsx', 'xlsm', 'ppt', 'pptx', 'docx', 'odt', 'docm', 'xml', 'csv'];

        if(estImage) {
            extEnabled = ['png', 'jpeg', 'jpg', 'gif', 'gmp'];
        }

        let invalidExt: boolean = (!extEnabled.includes(file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()));

        // Message d'erreur si l'extension n'est pas parmie celles autorisées
        if (invalidExt) {
            dispatch(newSnackbar({
                type: SnackbarType.WARNING,
                props: {
                    open: true,
                    autoHideDuration: 5000,
                    message: `Fichier au mauvais format. Vérifier que l'extension de vos fichiers figure parmis les formats suivants : ${ estImage ? 'png, jpeg, jpg, gif ou bmp.' : 'png, jpeg, jpg, gif, pdf, xls, xlsx, xlsm, ppt, pptx, docx, odt, docm ou xml.'}  `

                }
            }))
        }

        // Si l'extension est autorisée, on associe le fichier sélectionné au post
        if (extEnabled.includes(file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase())) {
            let tmpPost = cloneDeep(statePost); 
            if(estImage) {
                tmpPost.fileImage = file;
            } else {
                tmpPost.fileDocument = file; 
            }
            setStatePost(tmpPost);
        }
    }


    async function publishPost() {
        setPublishingPost(true);

        // if (elements['ref_metier']) {
        //     statePost.idReferentielMetier = referentiel.idReferentielMetier;
        // }

        for (const canal of stateCanaux) {
            statePost.notification = envoiNotif;
            statePost.idReferentielMetier = Number.parseInt(canal);
            //     statePost.fileVideo = statePost.fileVideo.minusData();
            let result = await statePost.publishPost(typeMur, envoiNotif);

            if(result){
                // On affiche les erreurs bloquantes s'il y en a
                if(result.level == 2){
                    dispatch(newSnackbar({
                        type: SnackbarType.ERROR,
                        props: {
                            open: true,
                            autoHideDuration: 5000,
                            message: `${result.message}`
        
                        }
                    }))
                }
                // On affiche les avertissements s'il y en a
                else if(result.level == 1){
                    dispatch(newSnackbar({
                        type: SnackbarType.INFO,
                        props: {
                            open: true,
                            autoHideDuration: 5000,
                            message: `${result.message}`
        
                        }
                    }))
                }
                // Si Pas de level 1 ou 2, c'est que tout se passe bien, 
                else {
                    dispatch(newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 5000,
                            message: `Le post a bien été ${modify ? "modifié" : "créé"}`
        
                        }
                    }))
                }
            } else {
                dispatch(newSnackbar({
                    type: SnackbarType.ERROR,
                    props: {
                        open: true,
                        autoHideDuration: 5000,
                        message: `Une erreur est survenue lors de la ${modify ? "modification" : "création"} de ce post`
    
                    }
                }))
            }
        }

        if (onClose) {
            onClose(true);
        } else {
            setShouldPostsListGetUpdated(true);
        }
        setPost(null);
        setStatePost(new Post());

        if (!isDesktopOrLaptop) {
            navigationPush('/')
        }
        setFileVideoBlob(null);

        resetPreUploadVideo();

        setPublishingPost(false);
        setConfirmerNotif(false);
        setEnvoiNotif(false);
    }

    function handleAvatarClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAvatarAnchorEl(event.currentTarget);
    }

    function handleCloseAvatar() {
        setAvatarAnchorEl(null);
    }

    function handleCustomPublishClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleCloseCustomPublish() {
        setAnchorEl(null);
    }

    function handleBrouillonClick() {
        let tmpPost = cloneDeep(statePost);
        tmpPost.actif = 2;
        setStatePost(tmpPost);
        setPublishingPost(true);
        setAnchorEl(null)
    }

    function handleScheduleClick() {
        setOpenSchedulePostDialog(true);
    }

    function handleCloseScheduleDialog(date: Moment) {
        if (date) {
            statePost.dateDebutAffichage = date.format('YYYYMMDDHHmmss');
            setPublishingPost(true);
        }
        setOpenSchedulePostDialog(false);
        setAnchorEl(null);
    }


    function handleChangeCanal(event: React.ChangeEvent<{
        name?: string;
        value: unknown;
    }>) {
        let transVar = event.target.value as string[];

        if (transVar.indexOf('-1') != -1 && stateCanaux.indexOf('-1') === -1) {
            transVar = ['-1']
        } else if (transVar.indexOf('-1') != -1 && transVar.length > 1) {
            transVar.splice(transVar.indexOf('-1'), 1);
        }

        setStateCanaux(transVar);
    }

    function checkPostValidity() {
        return statePost.post != '' && (statePost.idType !== 6 || (statePost.reponses[0] != "" && statePost.reponses[1] != ""));
    }

    function handleAvatarMenuItemClick(acteur: Acteur) {
        const tmpPost = cloneDeep(statePost);
        tmpPost.acteur = cloneDeep(acteur);
        tmpPost.idReferentielActeur = acteur.idActeur

        setStatePost(tmpPost);
        setAvatarAnchorEl(null);
    }

    const handleConfirmNotif = () => {
        setPublishingPost(true);
    };

    if (!statePost || loadingPost) {
        return <Pacman />;
    }

    return (
        <Box>
            {
                //(elements['create_post_public'] || elements['type_post']) &&
                ((elements['type_post'] && statePost.typePost) || (canaux && stateCanaux.length > 0)) &&
                <Fragment>
                    {
                        !isDesktopOrLaptop && !IS_ADMIN ?
                            <Paper className={classes.paperInfos} elevation={0}>
                                <Box display='flex' justifyContent='space-between' paddingX={1} paddingY={2}>

                                    <Box>
                                        {
                                            canaux && stateCanaux.length > 0 &&
                                            <Typography onClick={elements['create_post_public'] ? (() => setOpenChoseCanalFromListDialog(true)) : undefined}>
                                                Publier sur : <span className={elements['create_post_public'] ? classes.canalSpan : ''}>{canaux[stateCanaux[0]].abreviation}</span>
                                            </Typography>
                                        }

                                    </Box>

                                    {
                                        (elements['type_post'] && statePost.typePost) &&
                                        <Box display='flex' flexWrap='wrap' alignItems='center' justifyContent='space-between'>
                                            <Typography> Type : <span onClick={() => setOpenChosePostTypeFromListDialog(true)} style={{ backgroundColor: statePost.typePost.couleur}} className={clsx(teamUpStyle.badgeAstuce, classes.badgeTypePost)}>{statePost.typePost.libelle}</span></Typography>

                                        </Box>
                                    }
                                </Box>

                            </Paper>
                            :
                            <Box display='flex' justifyContent='space-between' marginBottom={2}>
                                {(elements['type_post'] && typesPost) &&
                                    <Select
                                        id={idSelectTypePost}
                                        ref={refSelectTypePost}
                                        style={{ border: `1px solid ${colors.lighterGrey.light}` }}
                                        inputProps={{ className: classes.inputsTypeCanal }}
                                        className={classes.selectsTypeCanal}
                                        IconComponent={KeyboardArrowDownSVG}
                                        variant='outlined'
                                        value={statePost.idType || Object.values(typesPost)[0].idTypePost}
                                        onChange={(event) => handleCloseChosePostTypeFromListDialog(event.target.value as number)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'right'
                                            },
                                            anchorEl: anchorElSelectTypePost,
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {Object.values(typesPost).map((typePost) =>
                                            <MenuItem value={typePost.idTypePost} key={`typepost-menu-${typePost.idTypePost}`}>
                                                <TypePostIcon icon={typePost.icone} color={typePost.couleur} class={classes.typePostIcon} />
                                                {
                                                    typePost.libelle
                                                }
                                            </MenuItem>
                                        )}
                                    </Select>
                                }
                                {
                                    (Object.values(canaux).length > 0 && stateCanaux.length > 0 && Object.values(canaux).filter(canal => canal.idReferentielMetier != -1)?.length != 1) &&
                                    <Select
                                        id={idSelectCanal}
                                        ref={refSelectCanal}
                                        // onLoad={(e)=> setAnchorElSelectCanal(e.target)}
                                        // on
                                        multiple
                                        // onClick={(e) => setAnchorElSelectCanal(e.target)}
                                        onChange={handleChangeCanal}
                                        variant='outlined'
                                        value={stateCanaux}
                                        renderValue={(selected) => (selected as string[]).map((value, index) => `${index ? ',' : ''}${canaux[value] ? canaux[value].abreviation : ''}`)}
                                        className={classes.selectsTypeCanal}
                                        IconComponent={KeyboardArrowDownSVG}
                                        style={props.modify && props.onClose ? { borderRadius: 0, borderBottomLeftRadius: '5px', border: `1px solid ${colors.lighterGrey.light}` } : {}}
                                        inputProps={{ className: classes.inputsTypeCanal }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'right'
                                            },
                                            anchorEl: anchorElSelectCanal,
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {
                                            Object.values(canaux).map((canal) =>

                                                <MenuItem key={`canal-menu-${canal.idReferentielMetier}`} disabled={stateCanaux.length === 1 && stateCanaux.indexOf(canal.idReferentielMetier.toString()) != -1} value={canal.idReferentielMetier.toString()}>
                                                    <Checkbox checked={stateCanaux.indexOf(canal.idReferentielMetier.toString()) != -1} />
                                                    {canal.abreviation}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                }
                            </Box>

                    }

                </Fragment>
            }
            {
                (elements[`${IS_ADMIN ? 'admin_' : ''}create_post_public`] || elements[`${IS_ADMIN ? 'admin_' : ''}create_post`]) &&
                <Paper className={classes.paperPost} elevation={0} style={{ border: `1px solid ${colors.lighterGrey.light}` }}
                >
                    {
                        (statePost.acteur || moi) &&

                        <Box display='flex'
                        // borderRadius='5px' 
                        // border={`1px solid ${colors.lighterGrey.light}`}
                        >
                            {
                                elements['cm']?.idActeur ?
                                    <Box onClick={handleAvatarClick} style={{ cursor: 'pointer' }} position='relative'>
                                        {
                                            avatarAnchorEl != null &&

                                            <Box position='absolute' zIndex={1} width={44} height={44} borderRadius='50%' bgcolor='rgba(0,0,0,0.3)'
                                                style={{ backgroundImage: `url('${OverlayAvatarSVG}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: '20px' }} />
                                        }
                                        <Avatar acteur={statePost.acteur || elements['cm']} variant='front' />
                                    </Box>
                                    :
                                    <Box position='relative'>
                                        <Avatar acteur={statePost.acteur || moi} variant='front' />
                                    </Box>
                            }

                            <TextField InputProps={{ style: { fontSize: 18, padding: '10px' }, className: classes.textFieldPostSpan }} value={statePost.post} onChange={(event) => { let tmpPost = cloneDeep(statePost); tmpPost.post = event.target.value; setStatePost(tmpPost); }} fullWidth placeholder='Exprimez-vous' multiline={true} rows={5} inputProps={{ maxLength: 65535 }} className={classes.textFieldPost} />
                            <Menu
                                anchorEl={avatarAnchorEl}
                                open={Boolean(avatarAnchorEl)}
                                onClose={handleCloseAvatar}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                }}>
                                <MenuItem onClick={(e) => handleAvatarMenuItemClick(moi)} className={classes.avatarMenuItem} ><Box marginRight={1}><Avatar variant='normal' acteur={moi} /></Box><Typography>{moi.prenom} {moi.nom}</Typography></MenuItem>
                                <MenuItem onClick={(e) => handleAvatarMenuItemClick(elements['cm'])} className={classes.avatarMenuItem} ><Box marginRight={1}><Avatar variant='normal' acteur={elements['cm']} /></Box><Typography>{elements['cm'].prenom} {elements['cm'].nom}</Typography></MenuItem>
                            </Menu>
                        </Box>

                    }
                    {
                        (statePost.typePost && statePost.typePost.idTypePost === 6) &&
                        <Fragment>
                            {
                                statePost.reponses.map((reponseSondage, index) =>
                                    <Box paddingY={0.5} key={`create-post-reponse${index}`}>
                                        {
                                            index == 2 ?
                                                <Collapse in={revealedThirdChoice}>
                                                    <TextField value={reponseSondage}
                                                        onChange={(e) => {
                                                            e.target.value = e.target.value.slice(0, 100);
                                                            //TODO : Mettre dans une méthode
                                                            let tmpPost = cloneDeep(statePost);

                                                            tmpPost.reponses[index] = e.target.value;
                                                            setStatePost(tmpPost);
                                                        }}
                                                        placeholder={`Choix ${index + 1}`}
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end" variant="filled" style={{ width: 70 }}>
                                                                    {(reponseSondage?.length ?? "0") + " / 100"}
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Collapse>
                                                :
                                                <TextField value={reponseSondage}
                                                    onChange={(e) => {
                                                        e.target.value = e.target.value.slice(0, 100);
                                                        let tmpPost = cloneDeep(statePost);

                                                        tmpPost.reponses[index] = e.target.value;
                                                        setStatePost(tmpPost);
                                                    }}
                                                    placeholder={`Choix ${index + 1}`}
                                                    fullWidth
                                                    InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end" variant="filled" style={{ width: 70 }}>
                                                                    {(reponseSondage?.length ?? "0") + " / 100"}
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                />
                                        }
                                    </Box>
                                )
                            }
                            <Box display='flex' justifyContent='center'>
                                <IconButton
                                    size='small'
                                    color='primary'
                                    onClick={(e) => {
                                        statePost.reponses[2] = '';
                                        setRevealedThirdChoice(!revealedThirdChoice);
                                    }}>
                                    {revealedThirdChoice ?
                                        <CancelSVG />
                                        :
                                        <AddCircleSVG />
                                    }
                                </IconButton>
                            </Box>
                        </Fragment>
                    }
                    <Box display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap' minHeight={45}>
                        <Box>
                            {
                                (!statePost.idType || statePost.idType != 6) ?
                                <Box display='flex' flexWrap='wrap'>
                                    {
                                        elements['image'] && !statePost.lienImage && !statePost.fileImage && !statePost.lienVideo && !statePost.fileVideo && !fileVideoBlob &&
                                        <Tooltip title='Ajouter photo'>
                                            <div>
                                                < B64FilePickerComponent
                                                    value={statePost.fileImage}
                                                    variant='icon'
                                                    ButtonImg={InsertPhotoSVG}
                                                    buttonColor={'secondary'}
                                                    buttonSize='medium'
                                                    inputAccept='.png,.jpg,.jpeg,.gif,.bmp'
                                                    onChange={(file) => { handleChangeFile(file, true) }}
                                                />
                                            </div>
                                        </Tooltip>
                                    }
                                    {
                                        elements['video'] && !statePost.lienImage && !statePost.fileImage && !statePost.lienVideo && !statePost.fileVideo && !fileVideoBlob &&
                                        <FilePickerComponent
                                            open={videoPickerOpen}
                                            fileType='file'
                                            inputAccept='video/mp4'
                                            variant='hidden'
                                            onChange={(f) => handleVideoFromGallery(f)}
                                            maxFileSize={200e6}
                                        />
                                    }
                                    {
                                        elements['video'] && !statePost.lienImage && !statePost.fileImage && !statePost.lienVideo && !statePost.fileVideo && !fileVideoBlob &&
                                        <Tooltip title='Ajouter une vidéo'>
                                            <IconButton color='secondary' onClick={() => setOpenVideoWayDialog(true)}><VideoCamSVG /></IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        elements['link'] && !statePost.lienUrl &&
                                        <Tooltip title='Ajouter lien URL'>
                                            <IconButton color='secondary' onClick={() => { setOpenVideoLinkDialog(true), setAddVideoLinkMethod((prevState: (v: string) => void) => handleCloseLinkDialog); setVideoDialog(false) }}><InsertLinkSVG /></IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        elements['document'] && !statePost.fileDocument &&
                                        <Tooltip title='Ajouter document'>
                                            <div>
                                                <B64FilePickerComponent
                                                    value={statePost.fileDocument}
                                                    fileType='file'
                                                    variant='icon'
                                                    buttonColor={'secondary'}
                                                    ButtonImg={InsertDriveFileIcon}
                                                    buttonSize='medium'
                                                    inputAccept='.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.pps,.ppsx'
                                                    onChange={(file) => { handleChangeFile(file, false) }}
                                                />
                                            </div>
                                        </Tooltip>
                                    }
                                    {
                                        (droits.hasOwnProperty('admin') && droits.hasOwnProperty('notifs_avancees') && envoiNotif) &&
                                        <Tooltip title="Envoyer une notification">
                                            <IconButton color='secondary' onClick={() => { setEnvoiNotif(false) }}><NotificationsIcon /></IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        (droits.hasOwnProperty('admin') && droits.hasOwnProperty('notifs_avancees') && !envoiNotif) &&
                                        <Tooltip title="Pas de notification">
                                            <IconButton color='secondary' onClick={() => { setEnvoiNotif(true) }}><NotificationsOffIcon /></IconButton>
                                        </Tooltip>
                                    }
                                </Box>
                                :
                                <Box display='flex' flexWrap='wrap'>
                                    {
                                        (droits.hasOwnProperty('admin') && droits.hasOwnProperty('notifs_avancees') && envoiNotif) &&
                                        <Tooltip title="Envoyer une notification">
                                            <IconButton color='secondary' onClick={() => { setEnvoiNotif(false) }}><NotificationsIcon /></IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        (droits.hasOwnProperty('admin') && droits.hasOwnProperty('notifs_avancees') && !envoiNotif) &&
                                        <Tooltip title="Pas de notification">
                                            <IconButton color='secondary' onClick={() => { setEnvoiNotif(true) }}><NotificationsOffIcon /></IconButton>
                                        </Tooltip>
                                    }
                                </Box>
                            }
                        </Box>
                        <Box position='relative'>
                            <ButtonGroup disabled={publishingPost || !checkPostValidity()} disableElevation variant='contained' color='primary' >
                                <Button style={{ border: 0 }} onClick={() => handlePublishClick()} variant='contained' size='large' color='primary'>{`${props.modify ? 'Modifier' : 'Publier'}`}</Button>
                                {
                                    (elements['programmer'] || elements['sauvegarder']) && !props.modify &&
                                    <Button style={{ border: 0, opacity: 0.75 }} className={classes.arrowButton} color='primary' size="small" onClick={(event) => handleCustomPublishClick(event)} variant='contained' ><KeyboardArrowDownSVG /></Button>
                                }
                            </ButtonGroup>
                            {
                                publishingPost &&
                                <CircularProgress size={25} color='primary' variant='indeterminate' style={{ position: 'absolute', margin: 'auto', top: 0, right: 0, left: 0, bottom: 0 }} />
                            }
                        </Box>
                    </Box>
                </Paper>
            }
            {
                statePost.fileImage ?
                    <Paper style={props.modify && props.onClose ? { border: `1px solid ${colors.lighterGrey.light}` } : {}} elevation={0} className={classes.paperPhotoVideo}>
                        <div style={{ position: 'relative', backgroundSize: 'cover', margin: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', height: 200, backgroundImage: `url('${statePost.fileImage.getSrc()}')` }} >
                            <IconButton size='small' style={{ color: colors.grey.dark, position: 'absolute', top: '-10px', right: '-10px' }} onClick={() => { let tmpPost = cloneDeep(statePost); tmpPost.fileImage = null; tmpPost.lienImage = null; setStatePost(tmpPost); }}><CancelSVG
                                style={{ borderRadius: '50%' }} className={classes.pathSvgCancel}
                            /></IconButton>
                        </div>
                    </Paper>
                    :
                    statePost.lienImage && <Paper style={props.modify && props.onClose ? { border: `1px solid ${colors.lighterGrey.light}` } : {}} elevation={0} className={classes.paperPhotoVideo}>
                        <div style={{ position: 'relative', backgroundSize: 'cover', margin: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', height: 200, backgroundImage: `url('${statePost.oldLienImage}')` }} >
                            <IconButton size='small' style={{ color: colors.grey.dark, position: 'absolute', top: '-10px', right: '-10px' }} onClick={() => { let tmpPost = cloneDeep(statePost); tmpPost.fileImage = null; tmpPost.lienImage = null; setStatePost(tmpPost); }}><CancelSVG
                                style={{ borderRadius: '50%' }} className={classes.pathSvgCancel}
                            /></IconButton>
                        </div>
                    </Paper>
            }
            {
                (statePost.fileVideo != null || fileVideoBlob) &&
                <Paper className={classes.paperPhotoVideo} elevation={0}>
                    <Box style={{ backgroundColor: "black" }} position='relative' display='flex' justifyContent='center' alignItems='center' margin="10px 0" borderRadius={5} overflow="hidden">
                        <video width="100%" style={{ maxHeight: "min(550px, 60vw)" }} playsInline controls>
                            <source type="video/mp4" src={statePost.fileVideo != null ? statePost.fileVideo.getSrc() : fileVideoBlob} />
                        </video>
                        <IconButton size='medium' style={{ zIndex: 20, color: colors.orange.main, position: 'absolute', top: '10px', right: '10px' }} onClick={() => { handleCancelPreUploadVideo() }}>
                            <CancelSVG style={{ borderRadius: '50%' }} className={classes.pathSvgCancel} />
                        </IconButton>
                        {preUploadVideoProgress != 100 && preUploadVideoProgress != 0 &&
                            <Box position="absolute" width="calc(100% - 20px)" height="calc(100% - 20px)" zIndex={10} bgcolor="rgba(0,0,0,0.8)" margin="auto" display="flex" alignItems="center" justifyContent="center">
                                <Box width="80%" height="80%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
                                    <Box textAlign="center" color="white" marginBottom="20px" fontSize="20px">Vidéo en cours de transfert</Box>
                                    <RoundLinearProgress variant="determinate" value={preUploadVideoProgress} className={classes.progressBar} />
                                </Box>
                            </Box>
                        }
                    </Box>
                </Paper>
            }
            {
                (statePost.lienVideo && !statePost.fileVideo) &&
                <Paper style={props.modify && props.onClose ? { border: `1px solid ${colors.lighterGrey.light}` } : {}} elevation={0} className={classes.paperPhotoVideo}>
                    <Box position='relative' display='flex' justifyContent='center' alignItems='center' padding={1}>
                        <IconButton size='small' style={{ color: colors.grey.dark, position: 'absolute', top: '-10px', right: '-10px' }} onClick={() => { let tmpPost = cloneDeep(statePost); tmpPost.lienVideo = null; setStatePost(tmpPost); }}><CancelSVG
                            style={{ borderRadius: '50%' }} className={classes.pathSvgCancel}
                        /></IconButton>

                        {
                            Momindum.canPlay(statePost.lienVideo) ?
                                <Momindum url={statePost.lienVideo} />
                            :
                            <>
                                <ReactPlayer style={{ borderRadius: 5, overflow: "hidden", zIndex: 1 }}
                                    url={statePost.lienVideo} onError={() => { 
                                        let tmpPost = cloneDeep(statePost); 
                                        tmpPost.lienVideo = null; 
                                        setStatePost(tmpPost);
                                        props.snackbar({
                                            type: SnackbarType.WARNING,
                                            props: {
                                                open: true,
                                                autoHideDuration: 5000,
                                                message: 'La vidéo n\'a pas pu être ajoutée car un problème est survenu.'
                                            }
                                        });                            
                                    }} onReady={() => setVideoReady(true)} />
                                {
                                    !videoReady &&
                                    <Box position='absolute'>
                                        <CircularProgress />
                                    </Box>
                                }
                            </>
                        }
                    </Box>
                </Paper>
            }
            {
                statePost.lienUrl &&
                <Paper style={props.modify && props.onClose ? { border: `1px solid ${colors.lighterGrey.light}` } : {}} elevation={0} className={classes.paperDocument}>
                    <Box display='flex'>
                        <Icon className={classes.iconLienUrl}><InsertLinkSVG /></Icon>
                        <Typography style={{ marginTop: "2px" }}>{statePost.lienUrl}</Typography>
                    </Box>
                    <IconButton size='small' style={{ color: colors.grey.dark }} onClick={() => { let tmpPost = cloneDeep(statePost); tmpPost.lienUrl = null; setStatePost(tmpPost); }}>
                        <CancelSVG style={{ borderRadius: '50%' }} className={classes.pathSvgCancel} />
                    </IconButton>
                </Paper>
            }
            {
                statePost.fileDocument ?
                    <Paper style={props.modify && props.onClose ? { border: `1px solid ${colors.lighterGrey.light}` } : {}} className={classes.paperDocument} elevation={isDesktopOrLaptop ? 0 : 1}>
                        <Box display='flex'>
                            <img src={filesIcons[statePost.fileDocument.getFileTypeFromName()]} style={{ marginRight: Theme.spacing(1) }} />
                            <Typography style={{ marginTop: "2px" }}>{statePost.fileDocument.name}</Typography>
                        </Box>
                        <IconButton size='small' style={{ color: colors.grey.dark }} onClick={() => { let tmpPost = cloneDeep(statePost); tmpPost.fileDocument = null; tmpPost.lienDocument = null; setStatePost(tmpPost); }} >
                            <CancelSVG style={{ borderRadius: '50%' }} className={classes.pathSvgCancel} />
                        </IconButton>
                    </Paper>
                    :
                    statePost.lienDocument && <Paper style={props.modify && props.onClose ? { border: `1px solid ${colors.lighterGrey.light}` } : {}} className={classes.paperDocument} elevation={isDesktopOrLaptop ? 0 : 1}>
                        <Box display='flex'>
                            <img src={filesIcons['pdf']} style={{ marginRight: Theme.spacing(1) }} />
                            <Typography style={{ marginTop: "2px" }}>{statePost.nomDocument}</Typography>
                        </Box>
                        <IconButton size='small' style={{ color: colors.grey.dark }} onClick={() => { let tmpPost = cloneDeep(statePost); tmpPost.fileDocument = null; tmpPost.lienDocument = null; setStatePost(tmpPost); }} >
                            <CancelSVG style={{ borderRadius: '50%' }} className={classes.pathSvgCancel} />
                        </IconButton>
                    </Paper>
            }
            <AddVideoLinkDialogComponent video={videoDialog} triggered={openVideoLinkDialog} onClose={(v) => addVideoLinkMethod(v)} />
            <SchedulePostDialogComponent triggered={openSchedulePostDialog} date={statePost.timestampDateDebutAffichage} onClose={(d) => handleCloseScheduleDialog(d)} />
            <ChoiceDialog choices={[
                { title: "Lien URL", icon: LienVideoSVG, onClick: () => { setOpenVideoLinkDialog(true); setVideoDialog(true); setAddVideoLinkMethod((prevState: (v: string) => void) => handleCloseVideoLinkDialog); } },
                { title: "Galerie", icon: VideoGalerieSVG, onClick: () => { setVideoPickerOpen(true); } },
            ]}
                triggered={openVideoWayDialog}
                onClose={() => setOpenVideoWayDialog(false)} />
            {
                elements['type_post'] &&
                <ChoseFromListDialogComponent currentSelectedKey={statePost.idType} triggered={openChosePostTypeFromListDialog} onClose={(d) => handleCloseChosePostTypeFromListDialog(d)} list={Object.values(typesPost).map((typePost) => ({ key: typePost.idTypePost, libelle: typePost.libelle }))} />
            }
            {
                canaux && Object.values(canaux).length > 1 &&
                <ChoseFromListDialogComponent currentSelectedKey={Number.parseInt(stateCanaux[0])} triggered={openChoseCanalFromListDialog} onClose={(d) => handleCloseChoseCanalFromListDialog(d)} list={Object.values(canaux).map((value, i) => ({ key: value.idReferentielMetier, libelle: value.abreviation }))} />

            }

            {
                (elements['programmer'] || elements['sauvegarder']) &&
                <Menu
                    id={"custom-publish-menu"}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => { handleCloseCustomPublish(); }}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}

                // className={classes.menuAnimationCard}
                >
                    {
                        elements['programmer'] &&
                        <MenuItem onClick={() => handleScheduleClick()}>Programmer</MenuItem>

                    }
                    {
                        elements['sauvegarder'] &&
                        <MenuItem onClick={() => handleBrouillonClick()}>Sauvegarder</MenuItem>

                    }
                </Menu>
            }

            <Dialog onClose={() => setConfirmerNotif(false)} open={confirmerNotif}>
                <DialogTitle>
                    <Typography variant="h6">Envoyer avec notification</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr(e) de vouloir publier le post en notifiant tous les acteurs ayant accès au fil ?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="contained" color="secondary" onClick={() => setConfirmerNotif(false)}>
                        Annuler
                    </Button>
                    <Button size="large" variant="contained" color="primary" onClick={handleConfirmNotif} disabled={publishingPost}>
                        Publier
                    </Button>
                </DialogActions>
            </Dialog>

        </Box >
    );
}

export default withRouter(connect(stateToProps, dispatchToProps)(withStyles(style)(CreatePostComponent)));