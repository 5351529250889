import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from 'axios';
import { replace, push } from 'connected-react-router';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import qs from 'qs';
import React, { Fragment, useEffect, useState, lazy, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from './store/rootStore';
import useStyle from './Root.style';
import useTeamUpStyle from './TeamUp.style';
import clsx from 'clsx';
import { loadCurrentActeurInfosAsync } from 'store/acteur/acteurActions';
import TodoList from './features/todolist/src/TodoList.component';
import AvatarComponent from './components/avatar/Avatar.component';
import DownloadAppDrawer from './components/drawer/DownloadAppDrawer.component';
import Header from './features/header/src/Header.component';
import { navigationSetCurrentModule } from './store/navigation/navigationActions';
import isEmpty from 'lodash/isEmpty'
import { Desktop, Mobile } from './components/mediaQueries/MediaQueries.component';
import { setLoggedIn } from './store/acteur/acteurActions';
import { setHeaderStore } from './features/header/src/store/actions'
import Faq from 'features/parametres/src/faq/Faq.component';
import { Button, SwipeableDrawer } from '@material-ui/core';
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions';
import { NotificationsService } from 'services/notifications/Notifications.service';
import { Detector } from 'react-detect-offline';
import { loadThemeAsync } from 'store/theme/themeActions';
import { useMatomo, MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import ConfirmationDialogComponent from 'components/dialog/ConfirmationDialog.component';
import { setUnreadNotifNb } from 'store/notifications/notificationsActions';
import ThemeCustom from "ThemeCustom";
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Badge } from '@capawesome/capacitor-badge';
import { PushNotifications } from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style, Animation } from '@capacitor/status-bar';

const Animations = lazy(() => import('features/animations/src/Animations.component'));
const Challenges = lazy(() => import('features/challenges/src/Challenges.hook.component'));
const Murs = lazy(() => import('features/murs/src/Murs.component'));
const Parametres = lazy(() => import('features/parametres/src/Parametres.component'));
const Notifications = lazy(() => import('features/notification/src/Notifications.component'));
const Login = lazy(() => import('./features/login/src/Login.component'));
const Messagerie = lazy(() => import('./features/messagerie/RootMessagerie.component'));
const LevelUp = lazy(() => import('./features/level_up/src/LevelUp.component'));
const PageContenuFront = lazy(() => import('./features/contenus/src/PageContenuFront.component'));

interface RootProps {
}
type MenuModule = {
    icon?: string,
    title: string,
    link: string
}

const moduleToMenuModule: {
    [key: string]: MenuModule
} = {
    'animation': {
        title: 'Animations',
        link: 'animation',
    },
    'challenge': {
        title: 'Challenges',
        link: 'challenge',
    },
    'fil_info': {
        title: 'Fil d\'information',
        link: 'fil-info',
    },
    'carnet_voyage': {
        title: 'Carnet de voyage',
        link: '',
    },
    'journal': {
        title: "Mur d'échanges",
        link: 'mur-echange',
    },
    'parametres': {
        title: "Aide",
        link: 'parametre',
    },
    'faq': {
        title: "FAQ",
        link: 'faq',
    },
    'todolist': {
        title: "To Do List",
        link: 'todolist',
    },
    'messagerie': {
        title: "Messagerie",
        link: 'messagerie',
    },
    'level_up': {
        title: "Antisèches",
        link: 'level-up',
    }
}

const Root: React.FC<RootProps> = props => {
    const classes = useStyle();
    const dispatch = useDispatch();

    const teamUpClasses = useTeamUpStyle();

    const [jwt, setJwt] = useState(localStorage.getItem('access_token'));
    const [matomo, setMatomo] = useState(null);
    const [updatePopup, setUpdatePopup] = useState(false);
    const [mustUpdate, setMustUpdate] = useState(false);

    const [module, pathname, currentActeur, loggedIn, droits, theme] = useSelector((state) => [
        state.navigation.currentModule, 
        state.router.location.pathname, 
        state.acteur.current, 
        state.acteur.loggedIn, 
        state.acteur.droits,
        state.theme.theme
    ], isEqual);

    useEffect(() => {
        if (theme?.url_matomo) {
            setMatomo(createInstance({
                urlBase: theme.url_matomo,
                siteId: 1,
                linkTracking: false
            }));
        }

        if (theme?.version_cordova && Capacitor.isNativePlatform()) {

            App.getInfo().then((info) => {
                if (theme.version_cordova > info.version) {
                    setUpdatePopup(true);
                }
                if (theme?.min_version_cordova > info.version) {
                    setMustUpdate(true);
                }
            });
        }

        if (theme?.logo_path) {
            let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
            link.href = `/dist/assets/${theme.logo_path}/icon.png`;
        }
    }, [theme]);

    const [authorizedModules, setAuthorizedModules] = useState<{[key: string]: boolean}>(null)
    const [open, setOpen] = React.useState(false);
    const [isPopupOpenAppli, setIsPopupOpenAppli] = React.useState(false);

    const { enableLinkTracking } = useMatomo()

    enableLinkTracking()

    async function loadNotifications() {
        Axios.get(
            `index.php?${qs.stringify({
                rub: 4,
                p: 3
            })}`
        ).then(({ data: { content } }) => {
            if (Capacitor.isNativePlatform()) {
                Badge.set({ count: content });
            }
            dispatch(setUnreadNotifNb(content))
        });
    }

    useEffect(() => {
        dispatch(loadThemeAsync.request());

        Axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error && error.response && error.response.status && 401 === error.response.status) {
                window.localStorage.removeItem('access_token');
                setJwt(null);
                dispatch(setLoggedIn(false))
            }
            return Promise.reject(error);

        });



        var jwt = localStorage.getItem('access_token');
        setJwt(jwt);
        verifyJwt();

        SplashScreen.hide();
    }, []);

    const setStatusBarStyleDark = async () => {
        await StatusBar.setStyle({ style: Style.Dark });
    };
      
    const setStatusBarStyleLight = async () => {
        await StatusBar.setStyle({ style: Style.Light });
    };

    const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();
      
        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }
      
        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
        }
      
        await PushNotifications.register();
    }

    const addListeners = async () => {
        await PushNotifications.addListener('registration', token => {
            console.info('Registration token: ', token.value);
            NotificationsService.getInstance().addAndroidSubscription(token.value);
            localStorage.setItem('fcm_token', token.value);
        });
      
        await PushNotifications.addListener('registrationError', err => {
            console.error('Registration error: ', err.error);
        });
      
        await PushNotifications.addListener('pushNotificationReceived', notification => {
            console.log('Push notification received: ', notification);

            dispatch(newSnackbar({
                type: SnackbarType.INFO,
                props: {
                    open: true,
                    action: (
                        <Button color="secondary" size="small" key='btn-launch' onClick={() => {
                            dispatch(push(notification.data.url))
                            // On récupère l'id et on marque la notif comme lue
                            NotificationsService.getInstance().vueNotification(notification.data.notID);
                        }}>
                            OUVRIR
                        </Button>
                    ),
                    autoHideDuration: 5000,
                    message: notification.title
                }
            }));
        });
      
        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
            console.log('Push notification action performed', notification.actionId, notification.notification);
            if (notification.actionId == 'tap') {
                dispatch(push(notification.notification.data.url));
                NotificationsService.getInstance().vueNotification(notification.notification.data.notID);
            } 
        });
    }

    useEffect(() => {
        if (!jwt) {
            if(pathname.search('/login/') == -1 && pathname.search('/page/') == -1) {
                dispatch(replace('/login/'))
            }
            document.body.style.background = "#fff"
            dispatch(setLoggedIn(false))

            // Remove token notif
            if (Capacitor.isNativePlatform()) {
                localStorage.removeItem('fcm_token');
                setStatusBarStyleLight();
            }

        } else {
            dispatch(loadCurrentActeurInfosAsync.request())
            dispatch(setLoggedIn(true))
            getAuthorizedModules();
            document.body.style.background = "#eee"
            
            // Si on utilise un package Capacitor
            if (Capacitor.isNativePlatform()) {
                addListeners();
                registerNotifications();
                StatusBar.show({ animation: Animation.None });
                setStatusBarStyleDark();
            }

            setInterval(function () {
                loadNotifications();
            }, 5000); 
            
        }
        
        // Pour récuperer depuis la config si on doit afficher la popup pour suggérer d'ouvrir l'appli mobile
        getIsPopupOpenAppli();
    }, [jwt]);

    useEffect(() => {
        if (jwt !== localStorage.getItem('access_token')) {
            setJwt(localStorage.getItem('access_token'))
        }
    }, [pathname])


    useEffect(() => {
        if (jwt && module == 'login') {
            dispatch(navigationSetCurrentModule('fil-info'));
            dispatch(replace('/fil-info/'))
        }
    }, [module])

    useEffect(() => {
        if (!loggedIn && jwt) {
            disconnect()
        }
    }, [loggedIn])

    async function verifyJwt() {
        await Axios.get(
            `index.php?${qs.stringify({
                p: 1
            })}`
        )
            .catch((data) => { });
    }

    function onClickMenu() {
        setOpen(!open)
    }

    /**
     * Obtenir la liste des modules auxquels l'utilisateur a accès
     */
    async function getAuthorizedModules() {
        await Axios.get(
            `index.php?${qs.stringify({
                rub: 400,
                p: 1
            })}`
        ).then(({ data: { content } }) => {
            setAuthorizedModules(content)
        })
            .catch((data) => { });
    }

    async function getIsPopupOpenAppli() {
        await Axios.get(
            `index.php?${qs.stringify({
                rub: 800,
                p: 13
            })}`
        ).then(({data : { content }}) => {
            setIsPopupOpenAppli(content)
        }).catch((content) => {console.log(content); setIsPopupOpenAppli(false)});
    }

    function navigateTo(link: string, titleModule: string) {
        dispatch(setHeaderStore({
            pageTitle: titleModule
        }))
        dispatch(push(`/${link}/`))
        setOpen(false);
    }

    /**
     * Supprime le token pour les notifications en back
     * puis détruit le JWT en front
     */
    async function disconnect() {
        await Axios.post(
            `index.php?${qs.stringify({
                rub: 800,
                p: 12,
                cordova: Capacitor.isNativePlatform() ? true : false,
                token: localStorage.getItem('fcm_token')
            })}`
        ).then(({ data: { content } }) => {
            // Pour réafficher la fenêtre de choix entre navigateur et appli
            window.localStorage.removeItem('hideDlPopup');
            window.localStorage.removeItem('access_token');
            dispatch(navigationSetCurrentModule('login'));
            setJwt(null);
            dispatch(push('/'))
        })
            .catch((data) => { });
    }
    return (
        <ThemeCustom>
            <>
            
                <MatomoProvider value={matomo}>
                {
                    jwt ?
                        <Box >
                            <Header onClickMenu={() => onClickMenu()} />

                            <SwipeableDrawer
                                anchor='left'
                                onOpen={() => setOpen(true)}
                                open={open}
                                onClose={() => setOpen(false)}
                                className={clsx(classes.drawer)}
                                PaperProps={{ className: clsx(classes.drawer) }}
                            >

                                {
                                    currentActeur &&
                                    <div className={classes.toolbar}>
                                        <Box onClick={() => navigateTo('parametre/mon-profil', 'Paramètres')} marginTop={2}>
                                            <AvatarComponent acteur={currentActeur} border='thin' variant='medium' />
                                        </Box>
                                        <Typography style={{ fontSize: 14, fontWeight: 700, color: 'white' }} className={clsx(teamUpClasses.mt10, teamUpClasses.mb5)}>{currentActeur.prenom} {currentActeur.nom}</Typography>
                                        <Typography style={{ fontSize: 12, color: 'white' }}>{currentActeur.idActeur}</Typography>

                                    </div>
                                }
                                <Divider />
                                <List>
                                    {
                                        authorizedModules && Object.keys(authorizedModules).map((module, index) => (
                                            authorizedModules[module] &&
                                            <ListItem button key={index} onClick={() => navigateTo(moduleToMenuModule[module].link, moduleToMenuModule[module].title)}>
                                                <ListItemIcon><img src={`/dist/assets/${theme?.logo_path}/menu/${moduleToMenuModule[module].link}.svg`} style={{ width: 24, height: 24 }} /></ListItemIcon>
                                                <ListItemText primary={moduleToMenuModule[module].title} />
                                            </ListItem>
                                        ))
                                    }
                                </List>

                            </SwipeableDrawer>
                            <Suspense fallback={
                                <Box display='flex' height={700} alignItems='center' justifyContent='center'>
                                    <CircularProgress />
                                </Box>
                            }>
                            {
                                !isEmpty(droits) &&
                                    <Switch>
                                        <Route path='/animation/'>
                                            <Animations />
                                        </Route>
                                        <Route path='/challenge/'>
                                            <Challenges />
                                        </Route>
                                        <Route path={`/faq/`} >
                                            <Faq />
                                        </Route>
                                        <Route path='/todolist/'>
                                            <TodoList />
                                        </Route>
                                        <Route path='/parametre/'>
                                            <Parametres />
                                        </Route>
                                        <Route path='/mur-echange/'>
                                            <Desktop>
                                                <Murs typeMur={2} />
                                            </Desktop>
                                            <Mobile>
                                                <div className={classes.containerListPosts}>
                                                    <Murs typeMur={2} />
                                                </div>
                                            </Mobile>
                                        </Route>
                                        <Route path='/fil-info/'>
                                            <Desktop>
                                                <Murs typeMur={1} />
                                            </Desktop>
                                            <Mobile>
                                                <div className={classes.containerListPosts}>
                                                    <Murs typeMur={1} />
                                                </div>
                                            </Mobile>
                                        </Route>
                                        <Route path='/notification/' >
                                            <Notifications />
                                        </Route>
                                        <Route path='/messagerie/'>
                                            <Messagerie />
                                        </Route>
                                        <Route path='/level-up/'>
                                            <LevelUp />
                                        </Route>
                                        <Route path='/page/:urlPage/'>
                                            <PageContenuFront />
                                        </Route>
                                        <Route path='/' >
                                            <Redirect to={`/${module}/`} />
                                        </Route>
                                    </Switch>
                            }
                            </Suspense>
                        </Box>
                        :
                        <Suspense fallback={ <CircularProgress />}>
                            <Switch>

                                <Route path="/login/">
                                    <Login />
                                </Route>
                                <Route path='/page/:urlPage/'>
                                    <PageContenuFront login />
                                </Route>
                                <Route path='/'>
                                    <Redirect to='/login/' />
                                </Route>
                            </Switch>
                        </Suspense>
                }

                {
                    Capacitor.isNativePlatform() &&
                    <Detector
                        onChange={(e) => {
                            dispatch(newSnackbar({
                                type: SnackbarType.WARNING,
                                props: {
                                    open: true,
                                    autoHideDuration: 5000,
                                    message: `Vous êtes ${e ? 'en' : 'hors'} ligne`
                                }
                            }));
                        }} 
                        render={() => <></>}
                        polling={{ enabled: true, interval: 20000, timeout: 5000, url: Axios.defaults.baseURL + "/index.php?p=1"}}
                    />
                }

                <ConfirmationDialogComponent
                    triggered={updatePopup}
                    onClose={(confirmation) => {
                        if (confirmation) {
                            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                                window.open(theme.lien_app_store);
                            } else {
                                window.open(theme.lien_play_store)
                            }
                        }
                        if (!mustUpdate) {
                            setUpdatePopup(false)
                        }
                    }}
                    title={`Mise à jour ${mustUpdate ? 'requise' : 'disponible'}`}
                    text="Une mise à jour de TeamUp est disponible sur les stores, veuillez mettre l'application à jour pour profiter des dernières fonctionnalités."
                    textOK="Télécharger"
                    KO={!mustUpdate}
                />
                </MatomoProvider>
                {isPopupOpenAppli &&
                    <DownloadAppDrawer />
                }
            
            </>
        </ThemeCustom>
    );
}


export default Root;